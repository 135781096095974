
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ReferringProvider } from "@/modules/provider/referring-provider.module";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Multiselect from "@vueform/multiselect";
import State from "@/components/ABilling/StateComponent.vue";
import Country from "@/components/ABilling/CounrtyComponent.vue";
import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import {
  addProvider,
  getProvider,
  updateProvider,
} from "@/api/referringProvider.api";
import { useRoute, useRouter } from "vue-router";

export interface Data {
  isDirty: boolean;
  previousReferringProvider?: ReferringProvider;
  referringProvider?: ReferringProvider;
  createNewMode: boolean;
  dict: {
    statuses: {
      code: string;
      name: string;
    }[];
  };
}
export default defineComponent({
  name: "Provider Page",
  components: { Multiselect, State, Country, PhoneNumberComponent },
  props: ["referringProviderId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props) {
    let organizationId = ref<string | null>("");
    const router = useRouter();
    const route = useRoute();
    let data = reactive<Data>({
      isDirty: false,
      referringProvider: undefined,
      createNewMode: true,
      dict: {
        statuses: [],
      },
    });

    watch(
      () => data.referringProvider,
      (currentValue) => {
        if (
          currentValue &&
          data.previousReferringProvider &&
          JSON.stringify(currentValue) !=
            JSON.stringify(data.previousReferringProvider)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    const isPhone = helpers.regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);// eslint-disable-line
    const npiReg = helpers.regex(/^\d{10,}$/);
    const npiRegMax = helpers.regex(/^\d{1,10}$/);

    const referringProviderRules = {
      referringProvider: {
        firstName: { required: helpers.withMessage("Required", required) },
        lastName: { required: helpers.withMessage("Required", required) },
        npi: {
          required: helpers.withMessage("Required", required),
          npiReg: helpers.withMessage("10 numbers required", npiReg),
          npiRegMax: helpers.withMessage(
            "Maximum of 10 numbers is required",
            npiRegMax
          ),
        },
        country: { required: helpers.withMessage("Required", required) },
        zip: { maxLength: maxLength(15) },
        phone: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        status: { required: helpers.withMessage("Required", required) },
      },
    };

    let v$ = useVuelidate(referringProviderRules, data as never);

    const breadcrumbPath = decodeURIComponent(
      String(route.query?.breadcrumb ?? "back")
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Referring Provider");
      data.dict.statuses = [
        { code: "active", name: "Active" },
        { code: "inactive", name: "Inactive" },
      ];
      if (props.referringProviderId) {
        data.referringProvider = await getProvider(props.referringProviderId);
        data.createNewMode = false;
      } else {
        data.createNewMode = true;
        data.referringProvider = {
          id: "",
          firstName: "",
          lastName: "",
          npi: "",
          status: "active",
          zip: "",
          state: null,
          country: "1",
        };
      }
      //US default
      if (data.referringProvider && !data.referringProvider.country) {
        data.referringProvider.country = "1";
      }
      data.previousReferringProvider = JSON.parse(
        JSON.stringify(data.referringProvider)
      );
    });

    async function cancel() {
      router.push(breadcrumbPath);
    }

    async function addItem() {
      const result = await v$.value.referringProvider.$validate();
      if (result) {
        const referringProviderId = await addProvider(data.referringProvider);
        if (referringProviderId) {
          data.isDirty = false;
          router.push(breadcrumbPath);
        }
      }
    }

    async function updateItem() {
      const result = await v$.value.referringProvider.$validate();
      if (result) {
        await updateProvider(data.referringProvider);
        data.isDirty = false;
        router.push(breadcrumbPath);
      }
    }

    return {
      organizationId,
      data,
      addItem,
      updateItem,
      cancel,
      v$,
    };
  },
});
